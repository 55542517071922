/*
 * @Description: 
 * @Author: Rabbiter
 * @Date: 2023-03-26 15:27:05
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseApi: "http://39.107.53.84:8080",
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
